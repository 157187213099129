<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box pa-4"
          :class="{
            'blocked-box': disabledView,
          }"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="9"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    18 Общие характеристики товара
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  class="justify-end d-flex"
                  style="z-index: 2"
                >
                  <v-text-field
                    v-model="searchValue"
                    outlined
                    tabindex="-1"
                    dense
                    clearable
                    hide-details="auto"
                    placeholder="Поиск по товарам"
                    background-color="white"
                    class="rounded-r-0"
                    @keypress.enter="search"
                    @click:clear="clearSearch"
                  />
                  <v-btn
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    dark
                    class="elevation-0 rounded-l-0"
                    @click="search"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    tabindex="-1"
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    color="#5CB7B1"
                    class="ml-3 px-0 elevation-0"
                    @click="togglePin"
                  >
                    <v-icon>
                      {{
                        pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin"
                      }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                :id="block_id"
                v-click-outside="triggerOnFocus"
              >
                <v-col cols="6">
                  <div class="d-flex align-center">
                    <div>
                      <label> Страна отпр. </label>
                      <v-autocomplete
                        ref="dispatch_country_letter"
                        v-model="about_wares.dispatch_country_letter"
                        class="mr-2"
                        style="width: 140px"
                        auto-select-first
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :filter="filterBySearchField"
                        :items="countries"
                        item-text="text"
                        item-value="letterCode"
                      />
                    </div>
                    <div>
                      <label> Страна назн. </label>
                      <v-autocomplete
                        ref="destination_country_letter"
                        v-model="about_wares.destination_country_letter"
                        class="mr-2"
                        style="width: 140px"
                        auto-select-first
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :filter="filterBySearchField"
                        :items="countries"
                        item-text="text"
                        item-value="letterCode"
                      />
                    </div>
                    <div>
                      <label>Общий вес, брутто, кг</label>
                      <input-numeric
                        v-model="about_wares.gross_weight"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex align-center">
                    <div>
                      <label> 22. Валюта и общая стоимость </label>
                      <div class="d-flex align-center">
                        <v-autocomplete
                          ref="invoice_currency_letter"
                          v-model="about_wares.invoice_currency_letter"
                          style="width: 110px"
                          class="mr-2"
                          auto-select-first
                          :items="currencies"
                          item-text="letterCode"
                          item-value="letterCode"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :filter="filterBySearchField"
                          @change="fetchCurrencyRate"
                        />
                        <input-numeric
                          ref="invoice_cost"
                          v-model="about_wares.invoice_cost"
                          class="mr-2"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :fraction="2"
                          @change="fetchCurrencyRate"
                        />
                      </div>
                    </div>
                    <div>
                      <label>Общая ТС </label>
                      <div class="d-flex align-center">
                        <input-numeric
                          ref="customs_cost"
                          :value="customs_cost"
                          outlined
                          class="mr-2"
                          dense
                          hide-details="auto"
                          disabled
                          suffix="BYN"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchCurrency } from "@/helpers/math";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
// import {getWaresParamTotal} from "@/helpers/math";
import { getCountryNameByCode } from "@/helpers/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";
import copyFieldMixin from "@/mixins/copy-field.mixin";

export default {
  components: { InputNumeric },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, copyFieldMixin],
  data: () => ({
    pinName: "goods-character",
    block_id: "zvt-goods-character",
    asyncDecision: {
      resolve: null,
      reject: null,
    },
    searchValue: "",
    currencyRate: {},
    declarationDate: "",
    about_wares: {
      declaration_id: null,
      gross_weight: null,
      invoice_cost: null,
      invoice_currency_letter: null,
      dispatch_country_letter: null,
      dispatch_country_name: null,
      destination_country_letter: null,
      destination_country_name: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      wares: "zvt/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies",
      pinned: "ui/getPinned",
      requests: "zvt/getRequests",
      packageTypes: "catalogs/getNsiPackageTypes",
      countries: "catalogs/getNsiCountries",
      disabledView: "zvt/getVisibility",
    }),
    // grossWeightSum() {
    //   return getWaresParamTotal(this.wares, "gross_weight");
    // },
    customs_cost() {
      return this.currencyRate.rate
        ? (
          this.currencyRate.rate * Number(this.about_wares.invoice_cost)
        ).toFixed(2)
        : "";
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    fetchCurrency,
    search() {
      this.$store
        .dispatch("zvt/searchInWares", {
          id: this.selected.id,
          value: this.searchValue,
        })
        .catch(() => this.$error("Не удалось выполнить поиск"));
    },
    clearSearch() {
      this.$store
        .dispatch("zvt/fetchGoods", this.selected.id)
        .then((res) => {
          this.$store.commit("zvt/REPLACE_GOODS_ITEM_IN_DECLARATION", res.data);
        })
        .catch(() => this.$error("Не удалось загрузить товары"));
    },
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
    setFields() {
      this.declarationDate =
        this.selected.about_declaration?.declaration_date ?? "";
      const about_wares = this.selected.about_wares ?? {};
      Object.keys(about_wares).forEach((key) => {
        if (key in this.about_wares) {
          this.about_wares[key] = about_wares[key];
        }
      });
      this.fetchCurrencyRate();
    },
    uploadData() {
      const about_wares = this.convertEmptyStringsToNull({
        ...this.about_wares,
        dispatch_country_name: getCountryNameByCode(
          this.countries,
          this.about_wares.dispatch_country_letter
        ),
        destination_country_name: getCountryNameByCode(
          this.countries,
          this.about_wares.destination_country_letter
        ),
      });

      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "about_wares",
        value: about_wares,
      });
    },
    fetchCurrencyRate() {
      if (this.declarationDate && this.about_wares.invoice_currency_letter) {
        this.fetchCurrency({
          date: this.declarationDate,
          code: this.about_wares.invoice_currency_letter,
        }).then((currency) => {
          if (currency) {
            this.currencyRate = currency;
          } else this.currencyRate = {};
        });
      }
    },
  },
};
</script>

<style scoped>
table,
tbody,
tr,
th,
td {
  background-color: #ededed !important;
}

th {
  border-bottom: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

td {
  border-bottom: transparent !important;
  padding: 0 10px 10px 0 !important;
}

table tr:last-child td {
  padding: 0 10px 4px 0 !important;
}

.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative;
}

.document-add {
  position: absolute;
  bottom: 7%;
  right: 24%;
}

.root-width {
  width: 1440px;
}

.pinned {
  position: fixed;
  top: 0;
  right: 50%;
  width: 100vw;
  transform: translateX(50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
</style>

<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
    cols="6"
    :order="isResident ? '3' : '2'"
  >
    <sender-collapsed
      v-show="collapsed"
      :sender="sender"
      @open="collapsed = false"
    >
      <v-switch
        v-show="isResident"
        :input-value="sender_json.declarant"
        hide-details="auto"
        dense
        class="mr-4"
        :disabled="contagentQueue"
        color="#5CB7B1"
        @change="changeDeclarant"
      >
        <template #label>
          <label style="font-size: 12px">Заявитель</label>
        </template>
      </v-switch>
    </sender-collapsed>
    <v-row
      v-show="!collapsed"
      class="statistics-box pb-4 ma-0"
      :class="{'blocked-box':disabledView}"
    >
      <v-col
        :cols="isResident ? 12 : 8"
        class="d-flex align-center"
      >
        <v-row>
          <v-col
            cols="5"
          >
            <span class="font-weight-bold">
              7 Отправитель
              <block-menu>
                <v-list-item
                  @click="show = true"
                >
                  Сохранить организацию в справочник
                </v-list-item>
                <v-list-item
                  v-if="isImport"
                  @click="openCatalogInNewTab('noResidents')"
                >
                  Открыть справочник нерезидентов
                </v-list-item>
              </block-menu>
            </span>
          </v-col>
          <v-col
            cols="7"
            class="d-flex justify-end"
          >
            <v-switch
              v-model="sender_json.individual"
              hide-details="auto"
              dense
              class="mr-4"
              color="#5CB7B1"
              @change="changeIndividual"
            >
              <template #label>
                <label style="font-size: 12px">Физ. лицо</label>
              </template>
            </v-switch>
            <v-switch
              v-show="isResident"
              :input-value="sender_json.declarant"
              hide-details="auto"
              class="mr-4"
              dense
              color="#5CB7B1"
              :disabled="contagentQueue"
              @change="changeDeclarant"
            >
              <template #label>
                <label style="font-size: 12px">Заявитель</label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="!isResident"
        cols="4"
        class="d-flex"
      >
        <v-autocomplete
          v-model="search"
          :loading="loading"
          :readonly="loading"
          :items="importers"
          item-text="own_name"
          :filter="filterBySearchField"
          placeholder="Поиск"
          auto-select-first
          return-object
          append-icon="mdi-magnify"
          background-color="white"
          dense
          outlined
          hide-details="true"
          @update:search-input="debouncedFetchImporters"
        />
      </v-col>
      <v-col
        cols="12"
        class="pb-0 d-flex"
      >
        <div
          style="width:12%;"
          class="mr-2"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="sender_json.country_letter"
              auto-select-first
              :filter="filterBySearchField"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="letterCode"
              item-value="letterCode"
            />
          </label>
        </div>
        <div
          v-show="!sender_json.individual"
          class="mr-2"
          style="width:20%;"
        >
          <label>
            УНП (ИНН И т.п.)
            <v-text-field
              ref="sender_inn"
              v-model="sender.sender_inn"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="!sender_json.individual"
          style="width:68%;"
        >
          <label>
            Наименование организации
            <v-text-field
              ref="sender_name"
              v-model="sender.sender_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="sender_json.individual"
          class="mr-2"
          style="width:20%;"
        >
          <label>
            Код док-а
            <v-autocomplete
              ref="identity_doc_code"
              v-model="sender_json.identity_doc_code"
              :items="identityDocumentsFiltered"
              item-text="search"
              auto-select-first
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
            />
          </label>
        </div>
        <div
          v-show="sender_json.individual"
          class="mr-2"
          style="width:23%;"
        >
          <label>
            Номер документа
            <v-text-field
              ref="personal_number"
              v-model="sender.sender_identity_doc_number"
              v-mask="'SS#######'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="sender_json.individual"
          style="width:23%;"
          class="mr-2"
        >
          <label>
            Дата документа
            <custom-date-picker
              ref="identity_doc_d_on"
              v-model="sender_json.identity_doc_d_on"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </label>
        </div>
        <div
          v-show="sender_json.individual"
          style="width:26%;"
        >
          <label>
            Личный номер
            <v-text-field
              ref="personal_number"
              v-model="sender_json.personal_number"
              v-mask="'#######S###SS#'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
      </v-col>
      <v-col
        v-show="sender_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Фамилия
          <v-text-field
            ref="sender_last_name"
            v-model="sender.sender_last_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-show="sender_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Имя
          <v-text-field
            ref="sender_first_name"
            v-model="sender.sender_first_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-show="sender_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Отчество
          <v-text-field
            ref="sender_middle_name"
            v-model="sender.sender_middle_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Индекс
          <v-text-field
            ref="postal_index"
            v-model="sender_json.postal_index"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="6"
        class="pb-0"
      >
        <label>
          Область/Район
          <v-text-field
            ref="region"
            v-model="sender_json.region"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pb-0"
      >
        <label>
          Город/Деревня
          <v-text-field
            ref="city"
            v-model="sender_json.city"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="8"
        class="pb-0"
      >
        <label>
          Улица
          <v-text-field
            ref="street_house"
            v-model="sender_json.street_house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Дом
          <v-text-field
            ref="house"
            v-model="sender_json.house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0 pl-0"
      >
        <label>
          Номер помещ.
          <v-text-field
            ref="room"
            v-model="sender_json.room"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <!--  -->
      <v-col
        v-if="!contacts.length"
        class="pb-0 d-flex align-end justify-start"
      >
        <v-btn
          dense
          class="elevation-0"
          @click="addContactItem"
        >
          <v-icon>mdi-plus</v-icon>
          Контакт
        </v-btn>
      </v-col>
      <v-col
        ref="contacts"
        cols="8"
      >
        <single-contact
          v-for="(contact, index) in contacts"
          :ref="'cont_' + index"
          :key="index"
          :types="contact_types"
          :item="contact"
          :idx="index"
          :contacts-length="contacts.length"
          @deleteContactItem="deleteContactItem"
          @addContactItem="addContactItem"
          @contactsUpdater="contactsUpdater"
        />
      </v-col>


      <!-- Филиал -->
      <v-col
        v-show="!sender_json.individual"
        cols="12"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div
              class="font-weight-bold d-flex align-center mt-2"
              :class="{ 'mb-2' : collapsedBranch }"
            >
              Филиал
              <v-btn
                style="z-index: 2"
                min-width="32px"
                class="ml-1"
                text
                @click="collapsedBranch = !collapsedBranch"
              >
                <v-icon>
                  {{
                    collapsedBranch ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="!collapsedBranch">
          <v-col
            cols="12"
            class="pb-0 d-flex"
          >
            <div
              style="width:12%;"
              class="mr-2"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="branch_country_letter"
                  v-model="sender_json.branch_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </label>
            </div>
            <div
              class="mr-2"
              style="width:20%;"
            >
              <label>
                УНП (ИНН И т.п.)
                <v-text-field
                  ref="sender_branch_inn"
                  v-model="sender.sender_branch_inn"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </div>
            <div
              style="width:68%;"
            >
              <label>
                Наименование организации
                <v-text-field
                  ref="sender_branch_name"
                  v-model="sender.sender_branch_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </div>
          </v-col>


          <v-col
            cols="2"
            class="pb-0"
          >
            <label>
              Индекс
              <v-text-field
                ref="branch_postal_index"
                v-model="sender_json.branch_postal_index"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="6"
            class="pb-0"
          >
            <label>
              Область/Район
              <v-text-field
                ref="branch_region"
                v-model="sender_json.branch_region"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="4"
            class="pb-0"
          >
            <label>
              Город/Деревня
              <v-text-field
                ref="branch_city"
                v-model="sender_json.branch_city"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col cols="8">
            <label>
              Улица
              <v-text-field
                ref="branch_street_house"
                v-model="sender_json.branch_street_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="2"
            class="pb-0"
          >
            <label>
              Дом
              <v-text-field
                ref="branch_house"
                v-model="sender_json.branch_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="2"
            class="pb-0 pl-0"
          >
            <label>
              Номер помещ.
              <v-text-field
                ref="branch_room"
                v-model="sender_json.branch_room"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div
              class="font-weight-bold d-flex align-center"
            >
              Сведения об уполномоченном экономическом  операторе
            </div>
          </v-col>
          <v-col
            cols="2"
          >
            <label>
              Страна
              <v-autocomplete
                ref="aeo_country_letter"
                v-model="sender_json.aeo_country_letter"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="countries"
                item-text="letterCode"
                item-value="letterCode"
                :filter="filterBySearchField"
              />
            </label>
          </v-col>
          <v-col
            cols="10"
          >
            <label>
              Номер по реестру
              <v-autocomplete
                ref="aeo_reg_number"
                v-model="sender_json.aeo_reg_number"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                class="mr-1"
                :items="aeos"
                item-value="licenseNumber"
                item-text="licenseNumber"
              />
            </label>
          </v-col>
        </v-row>
      </v-col>
      <archive
        :show.sync="show"
        :name="sender.sender_name"
        @save="saveToArchive"
      />
    </v-row>
  </v-col>
</template>

<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import SingleContact from '../single-contact.vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {getCountryNameByCode, openCatalogInNewTab} from "@/helpers/catalogs";
import { getSenderFromResident } from "@/helpers/catalogs";
import Archive from "./archive.vue";
import { highlightField } from '@/helpers/control'
import { sender as onSender } from '@/events/statistics/control'
import blockVisibility from '@/mixins/block-visibility'
import SenderCollapsed from './sender-collapsed.vue'
import BlockMenu from "@/components/documents/block-menu.vue";
import {debounce} from "@/helpers/inputs";

export default {
  components: {
    BlockMenu,
    CustomDatePicker,
    SingleContact,
    SenderCollapsed,
    Archive
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  props: {
    contagentQueue: {
      type: Boolean,
      default: false
    }
  },
  data:() => ({
    block_id: 'zvt-sender',
    collapsed: false,
    collapsedBranch: true,
    sender: {
      declaration_id: null,
      sender_last_name: "",
      sender_middle_name: "",
      sender_name: "",
      sender_branch_inn: "",
      sender_branch_name: "",
      sender_first_name: "",
      sender_identity_doc_number: "",
      sender_inn: ""
    },
    sender_json: {
      individual:false,
      aeo_country_letter: "",
      aeo_country_name: "",
      aeo_reg_number: "",
      branch_city: "",
      branch_country_letter: "",
      branch_country_name: "",
      branch_house: "",
      branch_postal_index: "",
      branch_region: "",
      branch_room: "",
      branch_street_house: "",
      city: "",
      country_letter: "",
      country_name: "",
      house: "",
      identity_doc_code: "",
      identity_doc_d_on: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      street_house: "",
      declarant:null
    },
    contacts: [],
    branch_contacts: [],
    // search
    loading: false,
    importers: [],
    search: null,
    show: false
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: 'zvt/getSelected',
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      aeos: "zvt/getNsiAeos",
      disabledView:"zvt/getVisibility",
      isImport:"zvt/isImport"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    isResident(){
      return this.selected?.type_declaration?.declaration_kind_code === 'ЭК'
    },
    identityDocumentsFiltered(){
      if(this.sender_json.country_letter){
        if( ['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.sender_json.country_letter)){
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.sender_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.uploadData().then(() => {
          this.$snackbar({text:"Обновлено [Заявитель]",color:"green", top:false, right: false});
        }).catch(() => {
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        })
      }
    },
    isResident(nv){
      if(nv === true && this.sender_json.declarant === false){
        this.collapsed = false
      }else{
        this.collapsed = nv === true;
      }
    },
  },
  created() {
    onSender.subscribe(this.onControl)
    this.debouncedFetchImporters = debounce(this.fetchImporters)
  },
  beforeDestroy() {
    onSender.unsubscribe()
  },
  methods:{
    openCatalogInNewTab,
    onControl(path){
      this.collapsed = false
      this.highlightField(path)
    },
    highlightField,
    saveToArchive(own_name) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: own_name,
        name: this.sender.sender_name,
        person_name: null,
        unp: this.sender.sender_inn,
        country_letter: this.sender_json.country_letter,
        country_name: this.sender_json.country_name,
        region: this.sender_json.region,
        city: this.sender_json.city,
        street: this.sender_json.street_house,
        house: this.sender_json.house,
        room: this.sender_json.room,
        postal_index: this.sender_json.postal_index,
        contacts: this.contacts,
        branch_name: this.sender.sender_branch_name,
        branch_unp: this.sender.sender_branch_inn,
        branch_country_letter: this.sender_json.branch_country_letter,
        branch_country_name: this.sender_json.branch_country_name,
        branch_region: this.sender_json.branch_region,
        branch_city: this.sender_json.branch_city,
        branch_street: this.sender_json.branch_street_house,
        branch_house: this.sender_json.branch_house,
        branch_room: this.sender_json.branch_room,
        branch_postal_index: this.sender_json.branch_postal_index,
        subject_doc_num: null,
        subject_doc_date: null,
        branch_contacts: [],
      };

      // подгонка контактов
      payload.contacts.map((i) => {
        i.contact = i.number;
        delete i.number;
      });

      // const url = this.selected?.type_declaration?.declaration_kind_code === 'ЭК' ? 'division/saveResidentRaw' : 'division/saveNoResidentRaw'

      // проверка на резидента
      let url = "";
      if (this.selected?.type_declaration?.declaration_kind_code === 'ЭК') {
        url = 'division/saveResidentRaw'
      } else {
        url = 'division/saveNoResidentRaw'
        payload.inn = payload.unp;
        delete payload.unp;
      }


      this.$store
        .dispatch(url, payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    updateFieldsOnSearch(item) {
      const { sender, sender_json } = getSenderFromResident({
        item,
        declaration_id: this.selected.sender.declaration_id,
      });

      this.sender = sender;
      this.sender_json = sender_json;
      // Обновляем контракт в блоке декларанта
      // const {subject_doc_date, subject_doc_num} = item
      // importerSelected.trigger({subject_doc_date, subject_doc_num})
    },
    debouncedFetchImporters(){},
    fetchImporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        const url = this.selected?.type_declaration?.declaration_kind_code === 'ЭК' ? 'searchResident' : 'searchNoResident'
        this.$store
          .dispatch(`division/${url}`, value)
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp}-${i.city}-${i.street}`,
              };
            });
            this.importers = [...items];
          })
          .catch(() => (this.loading = false));
      }
    },
    changeDeclarant(isDeclarant){
      if(isDeclarant){
        this.clearState()
      }
      this.sender_json.declarant = isDeclarant
      this.collapsed = isDeclarant
      this.$emit('changeContagentQueue', true)
    },
    changeIndividual(){
      if(this.sender_json.individual === false){
        this.sender_json.personal_number = null
        this.sender.sender_identity_doc_number = null
        this.sender_json.identity_doc_d_on = null
        this.sender.sender_last_name = null
        this.sender.sender_first_name = null
        this.sender.sender_middle_name = null
      }else{
        this.sender.sender_inn = null
        this.sender.sender_name = null
      }
      this.readyToUpdate()
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addContactItem(type = '') {
      const contact = {
        code: "",
        name: "",
        number: "",
      }
      if (type === 'branch') {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === 'branch') {
        this.branch_contacts.splice(index, 1)
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    contactsUpdater(payload, type) {
      if (type === 'branch') {
        this.branch_contacts[payload.index] = payload.cont_item;
      } else {
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    setFields() {
      const sender = this.selected.sender
      const sender_json = this.selected.sender.sender_json

      const contacts = sender_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);

      Object.keys(this.sender).forEach(key => {
        if(key in sender){
          this.sender[key] = sender[key]
        }
      })

      Object.keys(this.sender_json).forEach(key => {
        if(key in sender_json){
          this.sender_json[key] = sender_json[key]
        }
      })

      if(!this.sender_json.declarant) this.collapsed = false
    },
    clearState(){
      Object.keys(this.sender).filter(key => !['declaration_id'].includes(key)).forEach((key) => this.sender[key] = null)
      Object.keys(this.sender_json).forEach((key) => this.sender_json[key] = null)
      this.sender_json.individual = false
      this.contacts = []
      this.branch_contacts = []
    },
    uploadData(){
      const sender = this.convertEmptyStringsToNull({
        ...this.sender,
        sender_json: {
          ...this.sender_json,
          country_name: getCountryNameByCode(this.countries, this.sender_json.country_letter) || null,
          branch_country_name: getCountryNameByCode(this.countries, this.sender_json.branch_country_letter) || null,
          aeo_country_name: getCountryNameByCode(this.countries, this.sender_json.aeo_country_letter) || null,
          contacts: this.contacts,
          branch_contacts: [],
        }
      })
      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "sender",
        value: sender,
      }).then(() => {
        this.$emit('changeContagentQueue', false)
      });
    }
  }
}
</script>

<template>
  <div class="mt-3">
    <v-data-table
      class="no-data-table-bottom-padding grey lighten-2"
      hide-default-header
      dense
      hide-default-footer
      :items-per-page="-1"
      :items="preparedItems"
      :headers="headers"
    >
      <template #[`item.sum`]="{item}">
        <div
          :class="{
            'red--text':item.sum < 0
          }"
        >
          {{ item.sum }}
        </div>
      </template>
      <template #no-data>
        <div class="grey lighten-2">
          {{ noDataTitle }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {TYPES} from "@/constants/including-costs-catalogs";
import {prettifyNumber} from "@/helpers/math";

export default {
  props:{
    items:{
      type:Array,
      required:true
    },
    noDataTitle:{
      required:false,
      type: String,
      default: "Отсутствуют данные"
    }
  },
  data(){
    return{
      totalCustomsCost:0,
      headers:[
        {
          text:"",
          value:"sum"
        },
        {
          text:"",
          value:"currency_letter"
        },
        {
          text:"",
          value:"type"
        },
      ]
    }
  },
  computed:{
    ...mapGetters({
      selected: "zvt/getSelected",
    }),
    preparedItems(){
      return this.items.map((including) => ({
        ...including,
        sum: prettifyNumber(including.sum),
        type: TYPES.find(i => i.value === including.type)?.text
      }))
    }
  },
}
</script>

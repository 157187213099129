<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box pb-4"
        :class="{
          'blocked-box':disabledView
        }"
      >
        <v-col
          cols="9"
          class="d-flex align-center "
        >
          <span class="font-weight-bold">16 Местонахождение товаров
            <v-menu offset-x>
              <template #activator="{ on }">
                <v-icon
                  tabindex="-1"
                  dense
                  class="mx-1"
                  v-on="on"
                >
                  mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-if="customFieldCheckerNull(storagesFiltered, ware_location_json.customs_store_license_number, 'licenseNumber')"
                  @click="hideCustomFields"
                >
                  Значение из справочника
                </v-list-item>
                <v-list-item
                  v-else
                  @click="showCustomFields"
                >
                  Произвольное значение
                </v-list-item>

              </v-list>
            </v-menu>
          </span>
          <span
            v-if="collapsed"
            class="px-2"
          > {{ collapsedTitle }}</span>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-end"
        >
          <v-autocomplete
            v-if="!collapsed"
            v-model="search"
            :items="ztks"
            auto-select-first
            append-icon="mdi-magnify"
            placeholder="Поиск ЗТК"
            item-text="text"
            item-value="licenseNumber"
            class="mr-1"
            :loading="loading"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
            tabindex="-1"
            @update:search-input="fetchZtk"
          />
          <v-btn
            style="z-index: 2"
            tabindex="-1"
            min-height="0px"
            text
            min-width="32px"

            @click="collapsed = !collapsed"
          >
            <v-icon>
              {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-show="!collapsed"
          cols="12"
        >
          <div
            class="d-flex px-1 py-1"
          >
            <div
              class="header__currency"
            >
              <label>Код</label>
              <v-autocomplete
                ref="customs_store_code"
                v-model="ware_location_json.customs_store_code"
                auto-select-first
                item-text="text"
                item-value="code"
                :items="customsStorageTypes"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
              />
            </div>
            <div class="header__summ">
              <label>Тамож. орган</label>
              <v-autocomplete
                ref="customs_code"
                v-model="ware_location_json.customs_code"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :items="customsFiltered"
                item-text="textZvt"
                item-value="codeZvt"
                :filter="filterBySearchField"
              />
            </div>
            <div class="header__code">
              <label>Номер ЗТК </label>
              <v-autocomplete
                v-show="!customFieldCheckerNull(storagesFiltered, ware_location_json.customs_store_license_number, 'licenseNumber')"
                ref="customs_store_license_number"
                v-model="ware_location_json.customs_store_license_number"
                :items="storagesFiltered"
                auto-select-first
                item-text="text"
                item-value="licenseNumber"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
              />
              <v-text-field
                v-show="customFieldCheckerNull(storagesFiltered, ware_location_json.customs_store_license_number, 'licenseNumber')"
                ref="customs_store_license_number__custom"
                v-model="ware_location_json.customs_store_license_number"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </div>
            <div class="header__document_number">
              <label>
                Номер документа
                <v-text-field
                  ref="document_number"
                  v-model="ware_location_json.register_document_number"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </div>
            <div class="header__document_country_letter">
              <label>
                Страна документа
                <v-autocomplete
                  ref="document_country_letter"
                  v-model="ware_location_json.register_document_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  item-text="text"
                  item-value="letterCode"
                  :items="countries"
                  :filter="filterBySearchField"
                />
              </label>
            </div>
          </div>
        </v-col>
        <v-col
          v-show="!collapsed"
          cols="12"
        >
          <v-row>
            <v-col
              cols="2"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="ware_location_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  item-text="text"
                  item-value="letterCode"
                  :items="countries"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="ware_location_json.region"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="ware_location_json.city"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="ware_location_json.street_house"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="ware_location_json.house"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                № Помещ.
                <v-text-field
                  ref="room"
                  v-model="ware_location_json.room"
                  class="mr-1"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="header__railway">
                <label>
                  Наименование железнодорожной станции / морского(речного) порта
                </label>
                <v-text-field
                  ref="place"
                  v-model="ware_location_json.place"
                  outlined
                  dense
                  background-color="white"
                  hide-details="auto"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {filterBySearchFieldMixin} from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import {getCountryNameByCode} from "@/helpers/catalogs"
import customFieldCheckerNull from "@/mixins/custom-field-checker";
import {highlightField} from "@/helpers/control";
import {ware_location as onWareLocation} from "@/events/statistics/control";

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, customFieldCheckerNull],
  data() {
    return {
      block_id: 'zvt-ware-location',
      collapsed: true,
      loading: false,
      ztks: [],
      search: '',
      ware_location: {
        declaration_id: null
      },
      ware_location_json: {
        customs_store_code: '',
        customs_code: '',
        customs_store_license_number: '',
        country_letter: '',
        country_name: '',
        region: '',
        city: '',
        street_house: '',
        house: '',
        room: '',
        place: '',
        register_document_number: '',
        register_document_country_letter: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: 'zvt/getSelected',
      storages: "catalogs/getNsiCustomsStorages",
      countries: "catalogs/getNsiCountries",
      customs: "catalogs/getNsiCustoms",
      customsStorageTypes: "catalogs/getNsiCustomsStorageTypes",
      disabledView: "zvt/getVisibility"
    }),
    collapsedTitle() {
      return ['customs_store_code','country_letter', 'customs_code', 'customs_store_license_number', 'region',
        'city', 'street_house', 'house', 'room', 'place', 'register_document_number', 'register_document_country_letter'].reduce((previousValue, currentValue) => {
        return this.ware_location_json[currentValue] ? previousValue + ' ' + this.ware_location_json[currentValue] : previousValue
      }, '').slice(0, 100)
    },
    storagesWithLocationSearch() {
      return this.storages.map((storage) => ({
        ...storage,
        search: `${storage.licenseNumber} - ${storage.name}-${storage.unp}-${storage.location}`.toLowerCase()
      }))
    },
    customsFiltered() {
      const exclude = ['01', '02', '22', '23']
      return this.customs.filter((j) => !exclude.includes(j.code)).map((i) => {
        return {
          ...i,
          codZvt: `${i.code}000`,
          textZvt: `${i.code}000 - ${i.name}`,
        }
      })
    },
    storagesFiltered() {
      let result = [...this.storagesWithLocationSearch]
      if (this.ware_location_json.customs_store_code === '11') {
        result = this.storagesWithLocationSearch.filter((i) => i.licenseNumber.startsWith('ВА') || i.licenseNumber.startsWith('ВБ') || i.licenseNumber.startsWith('СВ'))
      }
      if (this.ware_location_json.customs_store_code === '21') {
        result = this.storagesWithLocationSearch.filter((i) => i.licenseNumber.startsWith('СА') || i.licenseNumber.startsWith('СБ') || i.licenseNumber.startsWith('ТС'))
      }
      //
      if (this.ware_location_json.customs_code === '09000') {
        result = result.filter((i) => i.licenseNumber.includes('-04') || i.licenseNumber.includes('-09'))
      }
      if (this.ware_location_json.customs_code === '06000') {
        result = result.filter((i) => i.licenseNumber.includes('-05') || i.licenseNumber.includes('-06'))
      }
      if (this.ware_location_json.customs_code === '07000') {
        result = result.filter((i) => i.licenseNumber.includes('-07') || i.licenseNumber.includes('-12'))
      }

      if (this.ware_location_json.customs_code === '14000') {
        result = result.filter((i) => i.licenseNumber.includes('-14') || i.licenseNumber.includes('-15'))
      }
      if (this.ware_location_json.customs_code === '16000') {
        result = result.filter((i) => i.licenseNumber.includes('-16') || i.licenseNumber.includes('-19'))
      }
      if (this.ware_location_json.customs_code === '20000') {
        result = result.filter((i) => i.licenseNumber.includes('-20'))
      }

      return result
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
    search: {
      handler(nv) {
        if (!nv) return
        if (nv.startsWith('ВА') || nv.startsWith('ВБ') || nv.startsWith('СВ')) {
          this.ware_location_json.customs_store_code = '11'
        }
        if (nv.startsWith('СА') || nv.startsWith('СБ') || nv.startsWith('ТС')) {
          this.ware_location_json.customs_store_code = '21'
        }
        //
        if (nv.includes('-04') || nv.includes('-09')) {
          this.ware_location_json.customs_code = '09000'
        }
        if (nv.includes('-05') || nv.includes('-06')) {
          this.ware_location_json.customs_code = '06000'
        }
        if (nv.includes('-07') || nv.includes('-12')) {
          this.ware_location_json.customs_code = '07000'
        }
        if (nv.includes('-14') || nv.includes('-15')) {
          this.ware_location_json.customs_code = '14000'
        }
        if (nv.includes('-16') || nv.includes('-19')) {
          this.ware_location_json.customs_code = '16000'
        }
        if (nv.includes('-20')) {
          this.ware_location_json.customs_code = '20000'
        }
        this.ware_location_json.customs_store_license_number = nv
        //
        this.$nextTick(() => {
          this.ztks = []
          this.search = ''

        })
      }
    }
  },
  created() {
    onWareLocation.subscribe(this.onControl)
  },
  beforeDestroy() {
    onWareLocation.unsubscribe()
  },
  methods: {
    highlightField,
    onControl(path) {
      this.collapsed = false
      this.highlightField(path)
    },
    showCustomFields() {
      this.ware_location_json.customs_store_license_number = ""
    },
    hideCustomFields() {
      this.ware_location_json.customs_store_license_number = null
    },
    fetchZtk(value) {
      if (value && value.length > 2) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.ztks = this.storagesWithLocationSearch
        }, 200)
      }
    },
    setFields() {
      const ware_location = this.selected.ware_location
      const ware_location_json = this.selected.ware_location.ware_location_json

      Object.keys(this.ware_location).forEach(key => {
        if (key in ware_location) {
          this.ware_location[key] = ware_location[key]
        }
      })

      Object.keys(this.ware_location_json).forEach(key => {
        if (key in ware_location_json) {
          this.ware_location_json[key] = ware_location_json[key]
        }
      })

    },
    uploadData() {
      const ware_location = this.convertEmptyStringsToNull({
        ...this.ware_location,
        ware_location_json: {
          ...this.ware_location_json,
          country_name: getCountryNameByCode(this.countries, this.ware_location_json.country_letter) || null,
        }
      })
      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "ware_location",
        value: ware_location,
      });
    }
  }
}
</script>

<style scoped>
/* .header__railway {
  width: 100% !important;
  padding: 0 0 0 100px;
} */

.header__code {
  width: 280px !important;
  padding: 0 10px 0 0;
}

.header__summ {
  width: 160px !important;
  padding: 0 10px 0 0;
}

.header__currency {
  width: 125px !important;
  padding: 0 10px 0 0;
}

.header__document_number,
.header__document_country_letter {
  width: 160px !important;
  padding: 0 10px 0 0;
}
</style>
